<template>
  <page-header-wrapper>
    <template v-slot:content>
      <div class="page-header-content">
        <a-card :bordered="false" class="content">
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="48">
                <a-col :md="16" :sm="48" style="display: blank">
                  <a-form-item label="管理对象">
                    <a-button type="primary">学生</a-button>&nbsp;
                    <a-button
                      @click="
                        (e) => {
                          $router.push('/staff/teacher')
                        }
                      "
                    >教师</a-button
                    >&nbsp;
                    <a-button
                      @click="
                        (e) => {
                          $router.push('/staff/officer')
                        }
                      "
                    >教管</a-button
                    >&nbsp;
                    <a-button
                      @click="
                        (e) => {
                          $router.push('/staff/principal')
                        }
                      "
                    >院长</a-button
                    >
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row :gutter="48">
                <a-col :md="6" :sm="18">
                  <a-form-item label="学校">
                    <a-select
                      v-model="queryParams.universityId"
                      v-hasPermi="['organization:university:list']"
                      @change="
                        (e) => {
                          getDepartmentList().then(
                            getGradeList().then(getSpecializeList()).then(getClassList().then(getStudentList()))
                          )
                          queryParams.departmentId = ''
                          queryParams.gradeId = ''
                          queryParams.specializeId = ''
                          queryParams.classId = ''
                        }
                      "
                    >
                      <a-select-option
                        v-for="item in universityList"
                        :value="item.universityId"
                        :key="item.universityId"
                      >{{ item.universityName }}</a-select-option
                      >
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="6" :sm="18">
                  <a-form-item label="分院">
                    <a-select
                      v-model="queryParams.departmentId"
                      v-hasPermi="['organization:department:list']"
                      @change="
                        (e) => {
                          getGradeList().then(getSpecializeList().then(getClassList().then(getStudentList())))
                          queryParams.gradeId = ''
                          queryParams.specializeId = ''
                          queryParams.classId = ''
                        }
                      "
                    >
                      <a-select-option
                        v-for="item in departmentList"
                        :value="item.departmentId"
                        :key="item.departmentId"
                      >{{ item.departmentName }}</a-select-option
                      >
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="6" :sm="18">
                  <a-form-item label="年级">
                    <a-select
                      v-model="queryParams.gradeId"
                      v-hasPermi="['organization:grade:list']"
                      @change="
                        (e) => {
                          getSpecializeList().then(getClassList().then(getStudentList()))
                          queryParams.specializeId = ''
                          queryParams.classId = ''
                        }
                      "
                    >
                      <a-select-option v-for="item in gradeList" :value="item.gradeId" :key="item.gradeId">{{
                        item.gradeName
                      }}</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row :gutter="48">
                <a-col :md="6" :sm="18">
                  <a-form-item label="专业">
                    <a-select
                      v-model="queryParams.specializeId"
                      v-hasPermi="['organization:specialize:list']"
                      @change="
                        (e) => {
                          getClassList().then(getStudentList())
                          queryParams.classId = ''
                        }
                      "
                    >
                      <a-select-option
                        v-for="item in specializeList"
                        :value="item.specializeId"
                        :key="item.specializeId"
                      >{{ item.specializeName }}</a-select-option
                      >
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="6" :sm="18">
                  <a-form-item label="班级">
                    <a-select
                      v-model="queryParams.classId"
                      v-hasPermi="['organization:class:list']"
                      @change="
                        (e) => {
                          getStudentList()
                        }
                      "
                    >
                      <a-select-option v-for="item in classList" :value="item.classId" :key="item.classId">{{
                        item.className
                      }}</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row :gutter="48">
                <a-col :md="6" :sm="18">
                  <a-form-item label="学生姓名">
                    <a-input placeholder="请输入" v-model="queryParams.stuName" />
                  </a-form-item>
                </a-col>
                <a-col :md="6" :sm="18">
                  <span class="table-page-search-submitButtons">
                    <a-button @click="handleQuery" type="primary">查询</a-button>
                    <a-button @click="resetQuery" style="margin-left: 8px">重置</a-button>
                  </span>
                </a-col>
              </a-row>
            </a-form>
          </div>
          <div class="table-page-operator-wrapper">
            <a-button
              @click="handleAddStudentClick"
              type="primary"
              v-hasPermi="['organization:class:add']"
              ghost
            >新增</a-button
            >
            <a-button @click="handleExport" type="primary" ghost>导出模板</a-button>
            <a-button @click="importExcel" type="primary" ghost>导入</a-button>
          </div>
          <!-- 表格 -->
          <a-table
            ref="table"
            :columns="columns"
            :loading="tableLoading"
            :data-source="studentList"
            row-key="stuId"
            :pagination="false"
          >
            <!-- 插槽指向状态 -->
            <span slot="sex" slot-scope="text, record">
              {{ sexFormat(record) }}
            </span>
            <span slot="status" slot-scope="text, record">
              {{ statusFormat(record) }}
            </span>
            <!-- <span slot="status" slot-scope="text, record">
							<a-popconfirm
								ok-text="是"
								cancel-text="否"
								@confirm="confirmHandleStatus(record)"
								@cancel="cancelHandleStatus(record)"
							>
								<span slot="title">确认<b>{{ record.status === '1' ? '启用' : '停用' }}</b>名称为:{{ record.stuName }}的学生吗?</span>
								<a-switch checked-children="开" un-checked-children="关" :checked="record.status == 0" />
							</a-popconfirm>
						</span> -->
            <!-- 更多选择 -->
            <span slot="action" slot-scope="text, record">
              <a @click="handleAccountLinkClick(record)" v-hasPermi="['sys:user:query']">{{
                record.userId ? '修改账号' : '创建账号1'
              }}</a>
              <a-divider type="vertical" />
              <a @click="$refs.createModal.show(record, true)" v-hasPermi="['staff:student:query']">详细</a>
              <a-divider type="vertical" />
              <a @click="$refs.createModal.show(record, false)" v-hasPermi="['staff:student:edit']">编辑</a>
              <a-divider type="vertical" />
              <a href="javascript:;" v-hasPermi="['staff:student:remove']" @click="handleDelete(record)">删除</a>
              <a-dropdown v-if="false">
                <a class="ant-dropdown-link"> 更多 <a-icon type="down" /> </a>
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a href="javascript:;" v-hasPermi="['staff:student:remove']" @click="handleDelete(record)">删除</a>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </span>
          </a-table>
          <!-- 底部分页按钮 -->
          <a-pagination
            class="ant-table-pagination"
            v-model="current"
            :page-size-options="pageSizeOptions"
            :total="total"
            show-size-changer
            :page-size="pageSize"
            @showSizeChange="onShowSizeChange"
            @change="currentPageChange"
          >
            <template slot="buildOptionText" slot-scope="props">
              <span v-if="props.value !== '50'">{{ props.value }}条/页</span>
              <span v-if="props.value === '50'">全部</span>
            </template>
          </a-pagination>
          <!-- 创建/编辑部门,单独封装了组件 -->
          <create-form ref="createModal" @handle-success="handleOk" />
          <reg-form ref="regModal" @handle-success="handleOk" />
        </a-card>
        <!-- 上传文件 -->
        <a-modal :title="upload.title" :visible="importExcelVisible" @cancel="importExcelHandleCancel" :footer="null">
          <a-upload-dragger
            name="file"
            accept=".xlsx, .xls"
            :multiple="true"
            :headers="upload.headers"
            :action="`${upload.url}?updateSupport=${upload.updateSupport}&universityId=${queryParams.universityId}&classId=${queryParams.classId}`"
            @change="importExcelHandleChange"
            :customRequest="importStudents"
          >
            <p class="ant-upload-drag-icon">
              <a-icon type="inbox" />
            </p>
            <p class="ant-upload-text">请将文件拖拽到此处上传❥(^_-)</p>
            <p class="ant-upload-hint">支持单个或批量上传，也可以点击后选择文件上传</p>
          </a-upload-dragger>
        </a-modal>
      </div>
    </template>
  </page-header-wrapper>
</template>

<script>
import storage from 'store'
// import { PageHeaderWrapper } from '@ant-design-vue/pro-layout'
import { listUniversity } from '@/api/rongyi/organization/university'
import { listDepartment } from '@/api/rongyi/organization/department'
import { listGrade } from '@/api/rongyi/organization/grade'
import { listSpecialize } from '@/api/rongyi/organization/specialize'
import { listClass } from '@/api/rongyi/organization/class'
import { listStudent, delStudent } from '@/api/rongyi/staff/student'
import { getUser } from '@/api/system/user'
import CreateForm from './modules/CreateForm'
import RegForm from './modules/RegForm'
import rryConfig from '@/../config/rongyiYun.config'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import request from '@/utils/request'

const statusMap = {
  0: {
    status: 'processing',
    text: '正常'
  },
  1: {
    status: 'default',
    text: '停用'
  }
}
export default {
  name: 'Class',
  components: {
    CreateForm,
    RegForm
  },
  data () {
    return {
      config: rryConfig,
      universityList: [],
      departmentList: [],
      gradeList: [],
      specializeList: [],
      classList: [],
      studentList: [],
      // 分页数据(默认第一页):
      pageSizeOptions: ['10', '20', '30', '40', '50'],
      current: 1,
      pageSize: 10,
      total: 0,
      statusMap: [],
      tableLoading: false,
      // 表格树数据
      // 部门树选项
      deptOptions: [],
      // 状态数据字典
      statusOptions: [],
      // 性别数据字典
      sexOptions: [],
      // 查询参数
      queryParams: {
        universityId: undefined,
        departmentId: undefined,
        gradeId: undefined,
        specializeId: undefined,
        classId: undefined,
        stuName: undefined,
        pageNum: 1,
        pageSize: 10
      },
      // 表头
      columns: [
        {
          title: '姓名',
          dataIndex: 'stuName'
        },
        {
          title: '性别',
          dataIndex: 'sex',
          scopedSlots: { customRender: 'sex' }
        },
        {
          title: '学号',
          dataIndex: 'stuNum'
        },
        {
          title: '手机',
          dataIndex: 'perPhone'
        },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '操作',
          width: '150',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 表格多选
      selectedRowKeys: [],
      selectedRows: [],
      rowSelection: {
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys
          this.selectedRows = selectedRows
        },
        onSelect: (record, selected, selectedRows) => {},
        onSelectAll: (selected, selectedRows, changeRows) => {}
      },
      importExcelVisible: false,
      // 用户导入参数
      upload: {
        // 是否显示弹出层（用户导入）
        open: false,
        // 弹出层标题（用户导入）
        title: '导入Excel',
        // 是否禁用上传
        isUploading: false,
        // 是否更新已经存在的用户数据
        updateSupport: 0,
        // 设置上传的请求头部
        headers: { Authorization: 'Bearer ' + storage.get(ACCESS_TOKEN) },
        // 上传的地址
        url: process.env.VUE_APP_API_BASE_URL + '/rongyi/student/importData'
      }
    }
  },
  // 状态过滤
  filters: {
    statusFilter (type) {
      return statusMap[type].text
    },
    statusTypeFilter (type) {
      return statusMap[type].status
    }
  },
  created () {
    // 初始化字典
    this.getDicts('sys_user_sex').then((response) => {
      this.sexOptions = response.data
    })
    this.getDicts('sys_normal_disable').then((response) => {
      this.statusOptions = response.data
    })

    this.queryParams.universityId = this.$route.query.uid
    this.queryParams.departmentId = this.$route.query.did
    this.queryParams.gradeId = this.$route.query.gid
    this.queryParams.specializeId = this.$route.query.sid
    this.queryParams.classId = this.$route.query.cid
    this.getUniversityList().then(
      this.getDepartmentList().then(
        this.getGradeList().then(this.getSpecializeList().then(this.getClassList().then(this.getStudentList())))
      )
    )
    // this.getDicts('sys_normal_disable').then(response => {
    //   this.statusOptions = response.data
    // })
    // console.log(this.$store.state);
    console.log('this.parentId1')
    console.log(this.parentId)
  },
  watch: {
    $route (to, from) {
      if (this.$route.query.uid) {
        this.parentId = this.$route.query.pid
        this.queryParams.specializeId = this.parentId
        this.queryParams.specializeName = ''
        this.getStudentList()
      }
    }
  },
  methods: {
    // confirmHandleStatus(row) {
    // 	row.status = row.status === '0' ? '1' : '0'
    // 	const text = row.status === '0' ? '启用' : '停用'
    // 	changeUserStatus(row.userId, row.status)
    // 	.then(() => {
    // 		this.$message.success(
    // 		text + '成功',
    // 		3
    // 		)
    // 	}).catch(function () {
    // 		this.$message.error(
    // 		text + '发生异常',
    // 		3
    // 		)
    // 	})
    // },
    // cancelHandleStatus() {

    // },
    // 导入excel弹框
    importExcel () {
      if (this.queryParams.universityId && this.queryParams.classId) {
        this.importExcelVisible = true
      } else {
        this.$message.error('请筛选学校、班级')
      }
    },
    // 导入excel变更
    importExcelHandleChange (info) {
      const status = info.file.status
      if (status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (status === 'done') {
        this.$message.success(`${info.file.name}: 导入成功`)
      } else if (status === 'error') {
        // this.$message.error(`${info.file.name}: 导入失败`)
      }
    },
    importStudents (fileObje) {
      const { file, onSuccess, onError } = fileObje
      const formData = new FormData()
      formData.append('file', file)
      formData.append('updateSupport', this.upload.updateSupport)
      formData.append('universityId', this.queryParams.universityId)
      formData.append('classId', this.queryParams.classId)
      request({
        url: '/rongyi/student/importData',
        method: 'post',
        data: formData
      })
        .then((response) => {
          onSuccess(response, file)
        })
        .catch((err) => {
          onError()
        })
    },
    // 导入弹窗关闭
    importExcelHandleCancel () {
      this.importExcelVisible = false
      // 关闭后刷新列表
      this.getStudentList()
    },
    /** 导出按钮操作 */
    handleExport () {
      this.download(
        'rongyi/student/importTemplate',
        {
          ...this.queryParams
        },
        `student_${new Date().getTime()}.xlsx`
      )
    },
    getUniversityList () {
      const p = new Promise((resolve, reject) => {
        listUniversity().then((response) => {
          if (response.code == 200) {
            this.universityList = response.rows
            resolve(response)
          } else {
            reject(response.msg)
          }
        })
      })
      return p
    },
    getDepartmentList () {
      const p = new Promise((resolve, reject) => {
        if (this.isEmptyStr(this.queryParams.universityId)) {
          reject('universityId is empty')
        } else {
          const param = {
            universityId: this.queryParams.universityId
          }
          listDepartment(param).then((response) => {
            if (response.code == 200) {
              this.departmentList = response.rows
              resolve(response)
            } else {
              reject(response.msg)
            }
          })
        }
      })
      return p
    },
    getGradeList () {
      const p = new Promise((resolve, reject) => {
        if (this.isEmptyStr(this.queryParams.universityId)) {
          reject('universityId is empty')
        } else if (this.isEmptyStr(this.queryParams.departmentId)) {
          reject('departmentId is empty')
        } else {
          const param = {
            universityId: this.queryParams.universityId,
            departmentId: this.queryParams.departmentId
          }
          listGrade(param).then((response) => {
            if (response.code == 200) {
              this.gradeList = response.rows
              resolve(response)
            } else {
              reject(response.msg)
            }
          })
        }
      })
      return p
    },
    getSpecializeList () {
      const p = new Promise((resolve, reject) => {
        if (this.isEmptyStr(this.queryParams.universityId)) {
          reject('universityId is empty')
        } else if (this.isEmptyStr(this.queryParams.departmentId)) {
          reject('departmentId is empty')
        } else if (this.isEmptyStr(this.queryParams.gradeId)) {
          reject('gradeId is empty')
        } else {
          const param = {
            universityId: this.queryParams.universityId,
            departmentId: this.queryParams.departmentId,
            gradeId: this.queryParams.gradeId
          }
          listSpecialize(param).then((response) => {
            if (response.code == 200) {
              this.specializeList = response.rows
              resolve(response)
            } else {
              reject(response.msg)
            }
          })
        }
      })
      return p
    },
    getClassList () {
      const that = this
      const p = new Promise((resolve, reject) => {
        if (that.isEmptyStr(this.queryParams.universityId)) {
          reject('universityId is empty')
        } else if (that.isEmptyStr(this.queryParams.departmentId)) {
          reject('departmentId is empty')
        } else if (that.isEmptyStr(this.queryParams.gradeId)) {
          reject('gradeId is empty')
        } else if (that.isEmptyStr(this.queryParams.specializeId)) {
          reject('specializeId is empty')
        } else {
          const param = {
            universityId: this.queryParams.universityId,
            departmentId: this.queryParams.departmentId,
            gradeId: this.queryParams.gradeId,
            specializeId: this.queryParams.specializeId
          }
          listClass(param).then((response) => {
            if (response.code == 200) {
              this.classList = response.rows
              resolve(response)
            } else {
              reject(response.msg)
            }
          })
        }
      })
      return p
    },
    getStudentList () {
      const p = new Promise((resolve, reject) => {
        const param = {
          universityId: this.queryParams.universityId,
          departmentId: this.queryParams.departmentId,
          gradeId: this.queryParams.gradeId,
          specializeId: this.queryParams.specializeId
        }
        listStudent(this.queryParams).then((response) => {
          if (response.code == 200) {
            this.studentList = response.rows
            this.total = response.total
            resolve(response)
          } else {
            reject(response.msg)
          }
        })
      })
      return p
    },
    /** 转换部门数据结构 */
    // normalizer(node) {
    //     if (node.children && !node.children.length) {
    //         delete node.children
    //     }
    //     return {
    //         id: node.deptId,
    //         label: node.deptName,
    //         children: node.children,
    //     }
    // },
    /** 搜索按钮操作 */
    handleQuery () {
      this.getStudentList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.resetForm('queryForm')
      this.current = 1
      this.pageSize = 10
      ;(this.queryParams = {
        universityId: undefined,
        departmentId: undefined,
        gradeId: undefined,
        specializeId: undefined,
        classId: undefined,
        stuName: undefined,
        pageNum: 1,
        pageSize: 10
      }),
        this.handleQuery()
    },
    handleAddStudentClick () {
      // console.log("this.queryParams")
      // console.log(this.queryParams)
      // if(isEmptyStr(this.queryParams.universityId)){
      //     this.$message.warning("请先选择学校！")
      // }else if(isEmptyStr(this.queryParams.departmentId)){
      //     this.$message.warning("请先选择分院！")
      // }else if(isEmptyStr(this.queryParams.gradeId)){
      //     this.$message.warning("请先选择年级！")
      // }else if(isEmptyStr(this.queryParams.specializeId)){
      //     this.$message.warning("请先选择年级！")
      // }else if(isEmptyStr(this.queryParams.classId)){
      //     this.$message.warning("请先选择班级！")
      // }else{
      //     this.$refs.createModal.show(null, false, this.queryParams.universityId,this.queryParams.departmentId,this.queryParams.gradeId,this.queryParams.specializeId);
      // }
      this.$refs.createModal.show(null, false)
    },
    // 生成账号
    handleAccountLinkClick (record) {
      let account = {}
      if (record.userId) {
        getUser(record.userId).then((res) => {
          if (res.code == 200) {
            account = res.data
            this.$refs.regModal.show(account, false, record)
          }
        })
      } else {
        // 新增
        account.nickName = record.stuName
        account.phonenumber = record.perPhone
        account.email = record.email
        account.password = '123456'
        account.sex = record.sex
        account.status = record.status
        account.hostType = 1
        account.hostId = record.universityId
        account.staffType = 1
        account.staffId = record.stuId
        account.postIds = []
        account.roleIds = [8]
        // account.roles= [{
        //     roleId: 8,
        //     roleKey: "sdt",
        //     roleName: "学生",
        //     roleSort: "8",

        // }]
        this.$refs.regModal.show(account, false, record)
      }
    },
    // 新增/修改框事件
    handleOk () {
      this.getStudentList()
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      const that = this
      this.$confirm({
        title: '警告',
        content: `真的要删除 名为${row.stuName}的数据项吗?`,
        okText: '删除',
        okType: 'danger',
        cancelText: '取消',
        onOk () {
          delStudent(row.stuId).then((response) => {
            if (response.code === 200) {
              that.$message.success('删除成功!')
              that.getStudentList()
            } else {
              that.$message.error(response.msg)
            }
          })
        }
      })
    },
    /** pageSize 变化的回调 */
    onShowSizeChange (current, pageSize) {
      this.current = current
      this.pageSize = pageSize
      this.queryParams.pageSize = pageSize
      this.queryParams.pageNum = current
      this.getStudentList()
    },
    /** 页码改变的回调 */
    currentPageChange (current, pageSize) {
      this.current = current
      this.pageSize = pageSize
      this.queryParams.pageSize = pageSize
      this.queryParams.pageNum = current
      this.getStudentList()
    },
    // 字典状态字典翻译
    statusFormat (row, column) {
      return this.selectDictLabel(this.statusOptions, row.status)
    },
    sexFormat (row, column) {
      return this.selectDictLabel(this.sexOptions, row.sex)
    }
  }
}
</script>
<style scoped>
.avatar {
  width: 32px;
  height: 32px;
}
</style>
